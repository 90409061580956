.MuiTreeNode-node {
    margin: 1px;
    border: 1px solid #dedddc;
    
}
.highlighted-item {
    background-color: grey !important;
    color: white;
}
 /* .MuiNodeContent-lineBlock {
    background-color: #eeeeee;
    margin: -7px;
    height: auto !important;
 } */