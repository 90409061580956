.react-flow__node-default  {
    border-radius: 0px;
    border: none !important;
    padding: 0px;
}

.react-flow__node-selectorNode {
    border-radius: 0px;
    border: none !important;
    padding: 0px !important;
}

.react-flow {
    background: rgb(224, 235, 235) !important;
  }

.node-border {
    height: 100px;
    /* width: 150px; */
    border-radius: 0px !important;
    border-left: 7px solid;
}

.default {
    border: none;
    height: 70px;
    width: 150px;
    border-radius: 0px !important;
    background-color: #34347d;
    color: white !important;
}

.defaultBody {
    height: 35px;
    background-color: #34347d;
    padding-top: 10px;
}

.defaultAddAction {
    background-color: black;
    height: 15px;
    display: flex;
    justify-content: center;
}

.cardUpdateAction {
    height: 20px;
    display: flex;
    justify-content: flex-end;
}

.updateIcon {
    font-size: 0.8rem !important;
    color: #34347d !important;
}

.cardBody {
    display: grid;
    height: 65px;
    text-align: center;
}

.bodyText {
    font-size: 10px !important;
}

.bodyDescription {
    height: 30px;
    padding-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis; 
    word-break: break-all;
}

.cardAddAction {
    background-color: #b5b5f5;
    height: 15px;
    display: flex;
    justify-content: center;
}

.addIcon {
    font-size: 0.8rem !important;
    color: white !important;
}